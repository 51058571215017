<template>
    <div class="second-container-list">
        <!-- <div class="container-search">
            <input type="search" class="form-control" placeholder="Buscar postulacion" />
        </div> -->
        <table class="table table-responsive table-hover caption-top">
            <thead class="table-header">
                <tr align="center">
                    <th class="vacante" scope="col">Vacante</th>
                    <th class="fecha" scope="col">Fecha de Postulación</th>
                    <th class="documentos" scope="col">Subir Documentos</th>
                    <th class="estatus" scope="col">Estatus</th>
                </tr>
            </thead>
            <tbody v-for="postulacion in postulaciones" v-bind:key="postulacion" class="table-row">
                <tr align="center">
                    <td style="width: 30%;" class="vacante">{{ postulacion.informationVacante.titulo }}</td>
                    <td class="fecha">{{ moment(postulacion.created_at).tz(`${userTimeZone}`).format('DD/MM/YYYY')
                        }}</td>
                    <td>
                        <div class="select-list-evaluados">
                            <router-link class="btn-asignar-evaluacion" v-if="postulacion.status === 'contratado'"
                                :to="`/postulaciones/documentos/${postulacion._id_vacante}`" title="Ver Evaluados">
                                <box-icon name='folder-open' type='solid' color="#03337A"></box-icon>
                            </router-link>
                            <span v-else class="btn-asignar-evaluacion" title="Ver Evaluados">
                                <box-icon name='folder-open' type='solid' color="#03337A"></box-icon>
                            </span>
                        </div>
                    </td>
                    <td class="status">
                        <span v-if="postulacion.status === 'contratado'" class="badge text-bg-success">{{
                postulacion.status }}</span>
                        <span v-else class="badge text-bg-dark">En Progreso</span>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>
<script>
import * as UserService from "../../services/user.service"
import moment from 'moment-timezone';

export default {
    data: () => ({
        listCompany: [],
        newClient: "",
        postulaciones: [],
        moment: moment,
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone

    }),
    mounted() {
        this.listClient();
    },
    methods: {
        async listClient() {
            let resp = await UserService.default.getPostulaciones();
            this.postulaciones = resp.data.data
        },
    },
};

</script>
<style land="scss">
@import "../../styles/registerClient.scss";
</style>